@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wave {
  -webkit-animation-name: wave-animation;
          animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s; /* Change to speed up or slow down */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; /* Never stop waving :) */
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.awesome {
  color:#a6d5fa;
  -webkit-animation: colorchange 10s infinite alternate;
          animation: colorchange 10s infinite alternate;
}

@-webkit-keyframes colorchange {
  25%  {color: #b7dfb9;}
  50% {color: #fab3ac;}
  75% {color: #a6d5fa;}
  100% {color: #ffd599;}
}

@keyframes colorchange {
  25%  {color: #b7dfb9;}
  50% {color: #fab3ac;}
  75% {color: #a6d5fa;}
  100% {color: #ffd599;}
}

@-webkit-keyframes wave-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes wave-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

